import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const carnes = () => {
  return (
    <Layout>
      <Seo title="Menu - Carne" defer={false} />
      <div className="w-full mx-auto lg:mt-10 flex flex-col">
        <div className="flex flex-col w-full h-20 bg-gray-200 justify-center items-center text-md lg:h-32 lg:text-xl lg:font-medium">
          <h1>Ementa</h1>
          <span className="text-xs font-extralight">
            Coma bem, viva melhor!
          </span>
        </div>
        <div className="w-full h-20 bg-carne-image bg-no-repeat bg-center bg-cover lg:h-48"></div>
        <div className=" w-4/5 mx-auto my-9 lg:my-20">
          <section className="grid grid-cols-1 gap-12 text-center lg:gap-14 lg:grid-cols-4 mb-10 ">
            <div>
              <div className="w-full h-full  ">
                <img
                  className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                  src="https://res.cloudinary.com/dobzqmm6v/image/upload/v1638530533/fotos%20menu/posta-monte-1_bx6qzn.jpg"
                  alt=""
                />
              </div>
              <span className=" text-sm md:text-base lg:text-lg">
                Posta à Monte Alegre
              </span>
            </div>
            <div>
              <div className="w-full h-full ">
                <img
                  className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                  src="https://res.cloudinary.com/dobzqmm6v/image/upload/v1638530533/fotos%20menu/picanha-1_ge32xh.jpg"
                  alt=""
                />
              </div>
              <span className=" text-sm md:text-base lg:text-lg">
                Picanha à Monte Alegre
              </span>
            </div>
            <div>
              <div className="w-full h-full ">
                <img
                  className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                  src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,w_700/v1639668244/novas%20fotos/1bcgqSJcZkStFRZ0ekw2gRkGxm8BuiNUW_svew6y.jpg"
                  alt=""
                />
              </div>
              <span className=" text-sm md:text-base lg:text-lg">
                Arroz de Pato
              </span>
            </div>
            <div>
              <div className="w-full h-full ">
                <img
                  className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                  src="https://res.cloudinary.com/dobzqmm6v/image/upload/v1638530533/fotos%20menu/medalhoes-novilho-bacon-2_lrf72t.jpg"
                  alt=""
                />
              </div>
              <span className=" text-sm md:text-base lg:text-lg">
                Medalhões de Novilho com Bacon
              </span>
            </div>
            <div>
              <div className="w-full h-full ">
                <img
                  className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                  src="https://res.cloudinary.com/dobzqmm6v/image/upload/v1638530532/fotos%20menu/bifinhos-bacon-2_w7mncm.jpg"
                  alt=""
                />
              </div>
              <span className=" text-sm md:text-base lg:text-lg">
                Bifinhos com Bacon
              </span>
            </div>
            <div>
              <div className="w-full h-full ">
                <img
                  className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                  src="https://res.cloudinary.com/dobzqmm6v/image/upload/v1638530532/fotos%20menu/bife-monte-1_piwfci.jpg"
                  alt=""
                />
              </div>
              <span className=" text-sm md:text-base lg:text-lg">
                Bife à Monte Alegre
              </span>
            </div>
            <div>
              <div className="w-full h-full">
                <img
                  className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                  src="https://res.cloudinary.com/dobzqmm6v/image/upload/v1638530532/fotos%20menu/medalhoes-novilho-2_tmh3x1.jpg"
                  alt=""
                />
              </div>
              <span className=" text-sm md:text-base lg:text-lg">
                Medalhoes de Novilho com Alheira e Broa
              </span>
            </div>
            <div>
              <div className="w-full h-full">
                <img
                  className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                  src="https://res.cloudinary.com/dobzqmm6v/image/upload/v1638530532/fotos%20menu/churrasquinh-vitela-1_qmu5po.jpg"
                  alt=""
                />
              </div>
              <span className=" text-sm md:text-base lg:text-lg">
                Churrasquinho de Vitela
              </span>
            </div>
          </section>
        </div>
        <Link to="/menu" className="mx-auto mb-5">
          <button
            type="button"
            className=" text-xs font-medium shadow-md h-8 px-2  text-white  transition-colors duration-150 bg-yellow-500 rounded-sm focus:shadow-outline hover:bg-yellow-600 md:px-5 md:h-8"
          >
            Ir para o menu
          </button>
        </Link>
      </div>
    </Layout>
  )
}

export default carnes
